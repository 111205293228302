import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  styled,
  Typography,
} from '@material-ui/core'

const AccordionContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    }
  }}
  padding-left: 4rem;
  padding-right: 4rem;
`

const AccordionTextContainer = styled(Box)`
  margin-top: 2rem;
`
const AccordionLabelTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1652)]: {
        fontSize: '1.3rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '1.2rem',
      },
      [theme.breakpoints.down(600)]: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    }
  }}
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  color: #008174;
  padding-left: 16px;
`

export const StyledButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        width: '90%',
        textAlign: 'center',
      },
    }
  }}

  margin-bottom: 1rem;
  margin-top: 1rem;
`
export const ButtonContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        textAlign: 'center',
      },
    }
  }}
`
export const StyledAccordion = styled(Accordion)`
  &::before {
    position: relative;
  }
  padding: 0;
  margin: 2px !important;
`
export const StyledAccordionSummary = styled(AccordionSummary)``

export const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        lineHeight: '1.8',
        marginBottom: '0.3rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    }
  }}
  padding-left: 16px;
  line-height: 2.1;
`
export const LastTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        lineHeight: '1.8',
        marginBottom: '2rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    }
  }}
  padding-left: 16px;
  line-height: 2.1;
  margin-bottom: 1rem;
`

export default function AccordionFeature() {
  return (
    <AccordionContainer>
      <AccordionTextContainer>
        <AccordionLabelTypography>Insurance policy</AccordionLabelTypography>
        <StyledTypography>
          <b>Tri Tag Rugby Personal Accident Insurance Policy</b>
        </StyledTypography>
        <LastTypography>
          Tri Tag Rugby has taken out Personal Accident Insurance to provide
          some level of comfort should you suffer the unlikely event of an
          accidental injury.
        </LastTypography>
        <LastTypography>
          We have provided some important information in layman’s terms to help
          you best understand your position before taking the field. For more
          information refer to the Product Disclosure Statement.
        </LastTypography>
        <StyledTypography>
          <b>What can Personal Accident Cover pay for?</b>
        </StyledTypography>
        <LastTypography>
          Personal Accident Cover CAN pay for NON-MEDICARE MEDICAL EXPENSES
          ONLY. ‘Non-Medicare’ means medical costs that can’t be claimed through
          Medicare. Items include physio, chiro, dental, ambulance transport,
          and private hospital accommodation.
        </LastTypography>
        <LastTypography>
          Personal Accident Cover is NOT Private Health insurance It is law that
          Medicare items can’t be claimed on Personal Accident Cover. These
          items can be claimed on Private Health Insurance and include services
          such as doctors, surgeons, and anaesthetists.
        </LastTypography>
        <LastTypography>
          Personal Accident Cover is NOT intended to be Life Insurance Personal
          Accident Cover is a policy for injuries occurring due to an accident.
          It is not a sickness or illness policy.
        </LastTypography>
        <StyledTypography>
          It is strongly recommended that all participants investigate their
          personal insurance needs. Participants should consider the benefits
          of:
        </StyledTypography>
        <StyledTypography>- Private Health insurance</StyledTypography>
        <StyledTypography>- Life Insurance</StyledTypography>
        <StyledTypography>
          - Loss of Income (particularly for self-employed players or those in
          heavy manual labour reliant employment)
        </StyledTypography>
        <LastTypography>- Ambulance Subscription</LastTypography>
        <StyledTypography>
          <b>What is Medicare?</b>
        </StyledTypography>
        <LastTypography>
          Medicare is a Commonwealth Government programme that provides eligible
          Australian residents with free or low-cost medical and hospital care.
          Medicare provides access to:
        </LastTypography>
        <LastTypography>
          Free treatment as a public (Medicare) patient in a public hospital
        </LastTypography>
        <LastTypography>
          Free or subsidised treatment by practitioners such as Doctors and
          Specialists
        </LastTypography>
        <StyledTypography>
          <b>What’s covered by Medicare?</b>
        </StyledTypography>
        <LastTypography>
          The MBS lists a rebate amount for each Medicare Item.
        </LastTypography>
        <LastTypography>
          For Example: A short consultation with a Doctor = Medicare rebate of
          $20.00.
        </LastTypography>
        <LastTypography>
          In some cases, doctors, surgeons or specialists may charge more than
          the MBS amount. The difference between what you have been charged and
          the amount listed on the MBS is called the “Medicare Gap”.
        </LastTypography>
        <LastTypography>
          Example: The Doctor charges you $45.00 for a short consultation.
          Medicare provides a rebate of $20.00 (as per the MBS). You are out of
          pocket $25.00 (this is known as the Medicare Gap).
        </LastTypography>
        <StyledTypography>
          <b>
            What’s the difference between Medicare and the Tri Tag Rugby
            Personal Accident Policy?
          </b>
        </StyledTypography>
        <LastTypography>
          The Health Insurance Act 1973 (Cth) strictly prohibits any general
          insurer from covering any item that is listed on the Medicare Benefits
          Schedule. This also means that regardless of your out of pocket
          expenses, it is against the law for the Insurer to cover you for the
          Medicare Gap.
        </LastTypography>
        <StyledTypography>
          <b>So what does that mean?</b>
        </StyledTypography>
        <LastTypography>
          If your medical or other injury related treatment has a Medicare Item
          Number, it is uninsurable and you may not claim for reimbursement
          through the policy. If your medical or other treatment does not have a
          Medicare Item Number, you may claim for reimbursement under the Tri
          Tag Rugby Personal Accident Policy.
        </LastTypography>
        <LastTypography>We cover;</LastTypography>
        <StyledTypography>
          - 85% of Non-Medicare Medical Expenses up to $1,500 with a benefit
          period of 52 weeks from the date of injury.
        </StyledTypography>
        <LastTypography>
          - 85% of Loss of Income up to a maximum of $250 per week with a
          52-week benefit period.
        </LastTypography>
        <LastTypography>
          An excess fee of $50 is deducted from any claim.
        </LastTypography>
        <LastTypography>We hope you never need it!</LastTypography>
        <LastTypography>
          In the unlikely event you do need to make a claim then please follow
          the instructions under the “how to make a claim” heading.
        </LastTypography>
        <StyledTypography>
          <b>How to make a claim</b>
        </StyledTypography>
        <LastTypography>
          Log into your Tri Tag Rugby account. Go to ‘More’, then select
          ‘Insurance Claim’ and download the claim form.
        </LastTypography>
        <StyledTypography>
          <b>Filling out the form</b>
        </StyledTypography>
        <LastTypography>You complete Section A and B.</LastTypography>
        <LastTypography>
          If you have a ‘Non Medicare Expense’ claim, you should also complete
          Section C. You should only submit this section of the form if you have
          completed all treatment, and no further treatment is required.
        </LastTypography>
        <LastTypography>Your Sports club completes Section D.</LastTypography>
        <LastTypography>
          Your Medical practitioner completes Section E.
        </LastTypography>
        <LastTypography>
          If you wish to claim for loss of earnings, your Employer completes
          Section F. Should you be self-employed, please ask your accountant to
          provide a written statement confirming your pre-tax earnings for the
          52 weeks immediately prior to your injury.
        </LastTypography>
        <LastTypography>
          If you went to hospital following an injury, attach a copy of the
          hospital admission notes.
        </LastTypography>
        <LastTypography>
          Check all questions have been answered (including by selecting either
          ‘Yes’ or ‘No’ wherever this option is given) and each Section has been
          signed and dated. Your claim will be delayed if the insurer has to
          return your claim form to you because it is incomplete.
        </LastTypography>
        <LastTypography>
          Please keep a copy of the completed claim form and attachments for
          your records.
        </LastTypography>
        <LastTypography>
          Send, or fax, or scan and email, or deliver your completed form in
          person to:
        </LastTypography>
        <LastTypography>ATC Insurance Solutions Pty Ltd</LastTypography>
        <StyledTypography>Level 9, 499 St Kilda Road,</StyledTypography>
        <LastTypography>Melbourne VIC 3004</LastTypography>
        <LastTypography>Fax: (03) 9867 5540</LastTypography>
        <LastTypography sx={{ marginBottom: '3rem' }}>
          Email: info@atcis.com.au
        </LastTypography>
        <LastTypography>
          Call ATC for any assistance when completing the claim form on 1800 994
          694.
        </LastTypography>
      </AccordionTextContainer>
    </AccordionContainer>
  )
}
