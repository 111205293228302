import { ReactElement } from 'react'
import Navbar from 'manage-tritag/components/navbar'
import MobileNavbar from 'manage-tritag/components/navbar/mobile'
import { useMediaQuery } from 'react-responsive'
import { PageProps } from 'gatsby'
import InsurancePolicyContent from 'manage-tritag/components/insurance-policy'
import { Box } from '@material-ui/core'
import Footer from 'manage-tritag/components/footer'

const InsurancePolicy = (props: PageProps): ReactElement => {
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        {isMobile ? (
          <MobileNavbar bg="#008174" />
        ) : (
          <Navbar path={props.location.pathname} bg="#008174" />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <InsurancePolicyContent />
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default InsurancePolicy
